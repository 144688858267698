import { API, Amplify } from 'aws-amplify';
import { getDistance } from 'geolib';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { Bar, Radar } from 'react-chartjs-2';
import * as gen from '../../generated';
import { kml } from '@tmcw/togeojson';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as geolib from 'geolib';

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import awsmobile from '../../aws-exports';
import NoAccess from '../../components/NoAccess';
import Spinner from '../../components/Spinner';
import { apiName, handleSearchRoute, handleSearchVehicles, selectColor } from '../../components/Utils';


ChartJS.register(
  ArcElement,
  CategoryScale,
  RadialLinearScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const segments = require('../../data/segments.json')

const routeOptions = [
  {
    label: 'ALL',
    value: null
  }, {
    label: 'Dumka',
    value: 'Dumka'
  }, {
    label: 'Pakur',
    value: 'Pakur'
  },
]

const TrafficDashboard = () => {
  const roles = useSelector((state) => state.userInfo.payload?.role)
  const allsettings = useSelector(state => state?.setting?.payload)
  const transporter_id = useSelector((state) => state.userInfo.payload?.transporter_id)

  const [vehicles, setVehicles] = useState([])
  const [spinner, setSpinner] = useState(true)
  const [segmentSpinner, setSegmentSpinner] = useState(true)
  const [counter, setCounter] = useState({ trips: 0, tippers: 0 })
  const [route, setRoute] = useState([])
  const [gatepasses, setGatepasses] = useState({})
  const [geoFence, setGeoFence] = useState([])
  const [geoFenceCount, setGeoFenceCount] = useState([])
  let subscription;
  let subscription2;


  const [segmentsData, setSegmentsData] = useState({
    loaded: [],
    unloaded: []
  })
  const [barGraphData, setBarGraphData] = useState({
    labels: [],
    data: {
      loaded: {
        moving: [],
        stopped: [],
        halted: []
      },
      unloaded: {
        moving: [],
        stopped: [],
        halted: []
      },
    }
  })
  const [radarGraphData, setRadarGraphData] = useState({
    labels: [],
    data: []
  })
  const [filters, setFilters] = useState({})

  const [eta, setEta] = useState({
    pakur: [],
    dumka: [],
    mine: []
  })

  const calculateCounters = () => {
    let gatepassData = Object.values(gatepasses)
    if (filters?.route) gatepassData = gatepassData.filter(item => item?.route_id === filters?.route?.value)
    if (gatepassData) {
      let totalTippers = []
      let totalTrips = []
      for (let item of gatepassData) {
        totalTippers.push(item?.vehicle_id)
        if (item?.challan_number) totalTrips.push(item?.challan_number)
      }
      totalTippers = new Set(totalTippers).size
      totalTrips = new Set(totalTrips).size

      setCounter({ trips: totalTrips, tippers: totalTippers })
    }
  }

  const fetchGatePasses = async (route) => {
    try {
      let query = `/gatepass?wb_start=${moment().startOf('day').unix()}&wb_end=${moment().endOf('day').unix()}&take=${999999999}`
      if (route?.value) query = `${query}&route_id=${route?.value}`
      let data = await API.get(apiName, query)
      let trips = {}
      for (let item of data.items) {
        trips[item.id] = item
      }
      setGatepasses(trips)

      Amplify.configure(gen.config)
      const subscription = gen.subscribe('gatepass', ({ data }) => {
        if (data) {
          let trip = JSON.parse(data)
          if (trip?.wb_exit_time) {
            let myTrip = trips[trip?.id]
            myTrip = trip
            trips = { ...trips, [trip?.id]: myTrip }
            setGatepasses(trips)
          }


        }
      })
      Amplify.configure(awsmobile)
      return () => {
        subscription.unsubscribe()
      }

    } catch (error) {
      console.log(error)
    }
  }

  function getGeofenceName(geofence, location) {
    for (const feature of geofence) {
      let polygon = [];
      for (let coordinate of feature.geometry.coordinates[0]) {
        polygon.push({
          latitude: coordinate[1],
          longitude: coordinate[0]
        });
      }
      const status = geolib?.isPointInPolygon(location, polygon);
      if (status === true) return feature.properties.name;
    }
    return null;
  }

  const fetchVehicles = async (kmlLayer, transporterId, geo) => {
    try {
      setSpinner(true)
      let query = `/vehicle?take=${999999999}`
      if (transporterId) query = `${query}&transporter_id=${transporterId}`

      let route = []
      await fetch(kmlLayer)
        .then(function (response) {
          return response.text();
        })
        .then(function (xml) {
          route = kml(new DOMParser().parseFromString(xml, "text/xml")).features
          route.map((item, idx) => item.segment_id = idx + 1)
          setRoute(route)
          return route
        });

      let x = []
      await fetch(geo)
        .then(function (response) {
          return response.text();
        })
        .then(function (xml) {
          x = kml(new DOMParser().parseFromString(xml, "text/xml")).features
        });
      let allGeoFence = x.map(item => item.properties.name).sort()

      let data = await API.get(apiName, query)
      let vehiclesMasterList = {}
      data.items = data.items.filter(item => item.deleted === false && item.gps_number !== null)

      for (let item of data.items) {
        let items = {
          id: item.id,
          deviated: data?.type_name === "COAL_TIPPER" ? item?.deviated || false : false,
          ignition: item?.ignition,
          location: { latitude: +item?.lat || 24.545407, longitude: +item?.lng || 87.451069 },
          gsmSignal: item?.signal,
          speed: item?.speed,
          gpsUpdatedAt: +item?.ts,
          segment: item?.segment,
          segment_no: route.find((x) => x?.properties?.name === item?.segment)?.segment_id,
          moving: item.moving === null || item.moving === false ? false : true,
          offline: item.offline === null || item.offline === true ? true : false,
          idle: item.idle === null || item.idle === false ? false : true,
          halted: item.halted === null || item.halted === false ? false : true,
          deviated_since: +item?.deviated_since,
          idle_since: +item?.idle_since,
          halted_since: +item?.halted_since,
          offline_since: +item?.offline_since,
          voltage: item?.voltage,
          geofence: getGeofenceName(x, { latitude: +item?.lat || 24.545407, longitude: +item?.lng || 87.451069 })
        }

        vehiclesMasterList[item.id] = { ...item, ...items }
      }
      setVehicles(vehiclesMasterList);
      const result = Object.groupBy(Object.values(vehiclesMasterList), ({ geofence }) => geofence);
      let count = {}
      for (let item of allGeoFence) {
        count[item] = { trucks: 0, loaders: 0 }
      }
      for (let item in result) {
        if (item !== 'null') {
          count[item].trucks = result[item].filter(item => item.type_id === 10003).length
          count[item].loaders = result[item].filter(item => item.type_id === 10026).length
        }
      }
      setGeoFenceCount(count)

      setSpinner(false)
      Amplify.configure(gen.config)
      subscription2 = gen.subscribe("vehicle_data", ({ data }) => {
        if (data) {
          const res = JSON.parse(data)
          const oldVehicle = Object.values(vehiclesMasterList).find(item => item.id === res.id)

          vehiclesMasterList[oldVehicle?.id] = { ...vehiclesMasterList[oldVehicle?.id], loaded: res?.loaded, suspicious: res?.suspicious };
          let temp = structuredClone(vehiclesMasterList)
          setVehicles(temp)
        }
      })

      subscription = gen.subscribe("vehicle", ({ data }) => {
        const vehicle = JSON.parse(data);
        const oldVehicle = Object.values(vehiclesMasterList).find(item => item.gps_number === vehicle.gpsNumber)
        vehicle.id = oldVehicle?.id

        if (vehicle && vehiclesMasterList[vehicle?.id]) {
          let myVehicle = vehiclesMasterList[vehicle?.id]

          myVehicle.speed = vehicle.speed
          myVehicle.gsmSignal = vehicle.gsmSignal
          myVehicle.gpsUpdatedAt = vehicle.gpsUpdatedAt
          myVehicle.gsmSignal = vehicle.signal
          myVehicle.ignition = vehicle.ignition
          myVehicle.moving = vehicle.moving || false
          myVehicle.halted = vehicle.halted || false
          myVehicle.offline = vehicle.offline
          myVehicle.idle = vehicle.idle || false
          myVehicle.deviated_since = +vehicle.deviated_since
          myVehicle.idle_since = +vehicle.idle_since
          myVehicle.halted_since = +vehicle.halted_since
          myVehicle.offline_since = +vehicle.offline_since
          myVehicle.suspicious = vehicle?.suspicious || false
          myVehicle.voltage = +vehicle?.voltage

          myVehicle.deviated = myVehicle?.type_name === "COAL_TIPPER" ? vehicle.deviated : false

          if (myVehicle.deviated === false && vehicle.deviated === true) myVehicle.deviatedAt = vehicle.gpsUpdatedAt
          if (myVehicle.deviated === true && vehicle.deviated === false) myVehicle.deviatedAt = null

          if (vehicle?.latitude && vehicle?.longitude) {
            let location = { latitude: +vehicle?.latitude, longitude: +vehicle?.longitude }
            myVehicle.location = location
            myVehicle.segment = vehicle?.segment
            myVehicle.geofence = getGeofenceName(x, location)
          }
          vehiclesMasterList[vehicle.id] = myVehicle;
          let temp = structuredClone(vehiclesMasterList)
          setVehicles(temp)
          // const result = Object.groupBy(Object.values(temp), ({ geofence }) => geofence);
          // for (let item in result) {
          //   if (item !== 'null') {
          //     count[item] = { trucks: 0, loaders: 0 }
          //     count[item].trucks = result[item].filter(item => item.type_id === 10003).length
          //     count[item].loaders = result[item].filter(item => item.type_id === 10026).length
          //   }
          // }
          // setGeoFenceCount(count)
        }
      })
      Amplify.configure(awsmobile);

      return () => {
        subscription.unsubscribe()
        subscription2.unsubscribe()
      }

    } catch (error) {
      console.log(error)
    }
  }

  const getVehicleInGeofence = async (geo) => {
    let x = []
    await fetch(geo)
      .then(function (response) {
        return response.text();
      })
      .then(function (xml) {
        x = kml(new DOMParser().parseFromString(xml, "text/xml")).features
      });
    let allGeoFence = x.map(item => item.properties.name).sort()
    let count = {}
    for (let item of allGeoFence) {
      count[item] = { trucks: 0, loaders: 0 }
    }

    let result = Object.groupBy(Object.values(vehicles), ({ geofence }) => geofence);
    if (filters?.route?.value) {
      let filteredVehicles = Object.values(vehicles).filter(item => item.route_id === filters?.route?.value)
      result = Object.groupBy(Object.values(filteredVehicles), ({ geofence }) => geofence);
    }
    for (let item in result) {
      if (item !== 'null') {
        count[item] = { trucks: 0, loaders: 0 }
        count[item].trucks = result[item].filter(item => item.type_id === 10003).length
        count[item].loaders = result[item].filter(item => item.type_id === 10026).length
      }
    }
    setGeoFenceCount(count)
  }

  const liveTrafficCalculation = () => {
    let data = Object.values(vehicles)

    if (filters?.route?.label && filters?.route?.label !== "ALL") {
      data = data?.filter((x) => x?.route_name === filters?.route?.label)
    }
    const segmentLabels = route?.map((item) => item?.properties?.name)
    const segmentdata = route?.map((item) => item?.properties?.name)

    const loadedmovingCount = []
    const loadedstopped = []
    const loadedHaltedCount = []
    const unloadedmovingCount = []
    const unloadedstopped = []
    const unloadedHaltedCount = []
    for (let item of segmentLabels) {
      const moving = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val.moving === true && val.offline === false && val?.type_name === "COAL_TIPPER" && val?.loaded === true)
      const stopped = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val.loaded === true && val.idle === true && val.offline === false)
      const halted = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val?.loaded === true && val.halted === true)

      const unloadedmoving = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val.moving === true && val.offline === false && val?.type_name === "COAL_TIPPER" && val?.loaded === false)
      const inloadedstopped = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val.loaded === false && val.idle === true && val.offline === false)
      const unloadedhalted = data?.filter((val) => val?.segment?.toLowerCase() === item?.toLowerCase() && val?.loaded === false && val.halted === true)

      loadedmovingCount.push(moving?.length)
      loadedstopped.push(stopped?.length)
      loadedHaltedCount.push(halted?.length)

      unloadedmovingCount.push(unloadedmoving?.length)
      unloadedstopped.push(inloadedstopped?.length)
      unloadedHaltedCount.push(unloadedhalted?.length)
    }

    setBarGraphData({
      labels: segmentdata,
      data: {
        loaded: {
          moving: loadedmovingCount,
          stopped: loadedstopped,
          halted: loadedHaltedCount
        },
        unloaded: {
          moving: unloadedmovingCount,
          stopped: unloadedstopped,
          halted: unloadedHaltedCount
        }
      }
    })

  }

  const segmentCalculation = async () => {
    setSegmentSpinner(true)
    let allSegments = Array.from(new Set(Object.values(vehicles).map(item => item.segment)))
    allSegments = allSegments.filter(item => item?.length > 2)

    let loadedVehicles = Object.values(vehicles).filter((x) => x?.loaded === true && x?.type_name === "COAL_TIPPER")
    let unloadedVehicles = Object.values(vehicles).filter((x) => x?.loaded === false && x?.type_name === "COAL_TIPPER")

    if (filters?.route?.label && filters?.route?.label !== "ALL") {
      loadedVehicles = loadedVehicles?.filter((x) => x?.route_name === filters?.route?.label)
      unloadedVehicles = unloadedVehicles?.filter((x) => x?.route_name === filters?.route?.label)
    }

    let loadedSegments = {}
    let unloadedSegments = {}
    allSegments.forEach((item) => {
      let dummy = {
        segNo: Object.values(vehicles)?.find(x => x.segment === item)?.segment_no,
        segName: item,
        vehicles: 0,
        idle: 0,
        halted: 0,
        moving: 0,
        offline: 0
      }
      loadedSegments[item] = dummy
      unloadedSegments[item] = dummy
    })

    for (let item of loadedVehicles) {
      if (item?.segment !== "-" && item?.segment) {
        const items = loadedVehicles?.filter((x) => x?.segment === item?.segment)
        let idle = items?.filter(x => x.loaded === true && x.idle === true && x.offline === false).length || 0;
        let halted = items?.filter(x => x.loaded === true && x.halted === true && x.offline === false).length || 0
        let offline = items?.filter(x => x.loaded === true && x.offline === true).length || 0

        loadedSegments[item?.segment] = {
          segNo: item?.segment_no,
          segName: item?.segment,
          vehicles: items?.length,
          idle,
          halted,
          moving: items?.filter((x) => x?.speed > 0).length,
          offline
        }
      }
    }

    const tempIdsLoaded = []

    tempIdsLoaded?.map((item) => {
      loadedSegments[`${item}`] = {
        segNo: item?.segment_no,
        segName: item?.segment,
        vehicles: 0,
        idle: 0,
        halted: 0,
        moving: 0,
        offline: 0
      }
    })

    for (let item of unloadedVehicles) {
      if (item?.segment !== "-" && item?.segment) {
        const items = unloadedVehicles?.filter((x) => x?.segment === item?.segment)
        let idle = items?.filter(x => x.ignition === true && x.speed === 0).length || 0;
        let halted = items?.filter(x => x.ignition === false && x.speed === 0).length || 0
        let offline = items?.filter(x => x.gsmSignal === 0).length || 0

        unloadedSegments[item?.segment] = {
          segNo: item?.segment_no,
          segName: item?.segment,
          vehicles: items?.length,
          idle,
          halted,
          moving: items?.filter((x) => x?.speed > 0).length,
          offline
        }
      }
    }
    const tempIdsUnloaded = []

    tempIdsUnloaded?.map((item) => {
      unloadedSegments[`${item}`] = {
        segNo: item?.segment_no,
        segName: item?.segment,
        vehicles: 0,
        idle: 0,
        halted: 0,
        moving: 0,
        offline: 0
      }
    })

    setSegmentsData((prev) => ({ ...prev, loaded: Object.values(loadedSegments).sort((a, b) => a?.segNo - b?.segNo) }))
    setSegmentsData((prev) => ({ ...prev, unloaded: Object.values(unloadedSegments).sort((a, b) => a?.segNo - b?.segNo) }))
    setSegmentSpinner(false)
  }

  const ETACalculation = (route) => {
    let pakur = Object.values(vehicles)?.filter((item) => item?.segment === "Silkutti" && item?.type_name === "COAL_TIPPER" && item?.speed > 0 && item?.loaded === true)
    let dumka = Object.values(vehicles)?.filter((item) => item?.segment === "Nakti" && item?.type_name === "COAL_TIPPER" && item?.speed > 0 && item?.loaded === true)
    let mine = Object.values(vehicles)?.filter((item) => item?.segment?.includes("Alubera") && item?.type_name === "COAL_TIPPER" && item?.speed > 0 && item?.loaded === false)

    let allRoute = [...pakur, ...dumka]
    if (filters?.route?.label && filters?.route?.label !== "ALL") {
      pakur = pakur?.filter((x) => x?.route_name.toLowerCase() === filters?.route?.label?.toLowerCase())
      dumka = dumka?.filter((x) => x?.route_name.toLowerCase() === filters?.route?.label?.toLowerCase())
      mine = mine?.filter((x) => x?.route_name.toLowerCase() === filters?.route?.label?.toLowerCase())
    }
    const pakurETA = formatETAData(pakur, 'PAKUR')
    const dumkaETA = formatETAData(dumka, 'DUMKA')
    const mineETA = formatETAData(mine, 'mine')
    const all = formatETAData(allRoute, 'all')

    setEta((prev) => ({ ...prev, pakur: pakurETA, dumka: dumkaETA, mine: mineETA, allRoute: all }))
  }

  const formatETAData = (data, route) => {
    const temp = []
    const dumkaRoute = { latitude: 24.28944158134833, longitude: 87.260474386027 }
    const pakurRoute = { latitude: 24.62302087060489, longitude: 87.839301955064 }
    for (let item of data) {
      let distance;
      if (route === 'PAKUR') {
        distance = getDistance(pakurRoute, item?.location) / 1000
      } else if (route === 'dumka') {
        distance = getDistance(dumkaRoute, item?.location) / 1000
      } else if (route === 'all') {
        if (item?.route_name === "Pakur") {
          distance = getDistance(pakurRoute, item?.location) / 1000
        } else if (item?.route_name === 'Dumka') {
          distance = getDistance(dumkaRoute, item?.location) / 1000
        }
      } else {
        distance = getDistance({ latitude: 24.54536215076811, longitude: 87.45181345653168 }, item?.location) / 1000
      }
      const speed = item?.speed
      if (distance && speed) {
        const hrs = Math.floor(distance / speed)
        const min = Math.round(((distance / speed) - hrs) * 60)
        const formattedHrs = String(hrs).padStart(2, "0");
        const formattedMinutes = String(min).padStart(2, "0");
        const eta = `${formattedHrs}:${formattedMinutes} hrs`
        const payload = {
          id: item?.id,
          type: item?.loaded,
          eta: eta,
          route: item?.route_name
        }
        temp.push(payload)
      }
    }
    let sortData = temp.sort(compareETA);
    sortData = sortData.slice(0, 10)
    return sortData
  }

  const compareETA = (a, b) => {
    const timeA = a.eta?.split(':').map(str => parseInt(str));
    const timeB = b.eta?.split(':').map(str => parseInt(str));

    // Compare hours first
    if (timeA[0] !== timeB[0]) {
      return timeA[0] - timeB[0];
    }

    // If hours are the same, compare minutes
    return timeA[1] - timeB[1];
  };

  const livetrackingData = {
    labels: barGraphData?.labels,
    datasets: [
      {
        label: 'Loaded Halted',
        backgroundColor: '#e25151',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#e25151',
        hoverBorderColor: '#e25151',
        data: barGraphData?.data?.loaded?.halted,
        stack: 'Stack 0',

      },
      {
        label: 'Loaded Stopped',
        backgroundColor: '#f7af03',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#f7af03',
        hoverBorderColor: '#f7af03',
        data: barGraphData?.data?.loaded?.stopped,
        stack: 'Stack 0'
      },

      {
        label: 'Loaded Moving',
        backgroundColor: '#42ba6a',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#42ba6a',
        hoverBorderColor: '#42ba6a',
        data: barGraphData?.data?.loaded?.moving,
        stack: 'Stack 0'
      },
      {
        label: 'Unloaded Stopped',
        backgroundColor: '#C08261',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#C08261',
        hoverBorderColor: '#C08261',
        data: barGraphData?.data?.unloaded?.stopped,
        stack: 'Stack 1'
      },
      {
        label: 'Unloaded Halted',
        backgroundColor: '#00abad',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#00abad',
        hoverBorderColor: '#00abad',
        data: barGraphData?.data?.unloaded?.halted,
        stack: 'Stack 1'
      },
      {
        label: 'Unloaded Moving',
        backgroundColor: '#337CCF',
        borderColor: '#0000',
        barThickness: "10",
        borderWidth: 3,
        hoverBackgroundColor: '#337CCF',
        hoverBorderColor: '#337CCF',
        data: barGraphData?.data?.unloaded?.moving,
        stack: 'Stack 1'
      },
    ],
  };

  const option = {
    plugins: [ChartDataLabels],
    responsive: true, // This makes the chart responsive to the container size
    maintainAspectRatio: false, // This option makes sure the aspect ratio is not maintained
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false, // Set this to false to hide the labels
      },
    },
    width: 400, // Customize the width of the chart
    height: 200, // Customize the height of the chart
  };


  const patrolingGraph = {
    labels: radarGraphData?.labels,
    datasets: [
      {
        label: 'Patrolling Vehicles',
        data: radarGraphData?.data, // Example data points
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const config = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
  };

  const filterPatrol = (value) => {
    setFilters((prev) => ({ ...prev, vehicle: { label: value, value: value } }))
    const vehicle = Object.values(vehicles)?.filter((item) => item.id === value)
    if (vehicle?.length === 0) return toast.error("Vehicle not available")

    const patrolingVehicles = Object.values(vehicles)?.filter((item) => item.type_name === "SECURITY")

    const distanceVehicles = []
    for (let item of patrolingVehicles) {
      if (item?.location?.latitude > 0 && vehicle[0]?.location?.longitude) {
        const payload = {
          id: item?.id,
          distance: getDistance(item?.location, vehicle[0]?.location) / 1000
        }
        distanceVehicles.push(payload)
      }
    }

    const labels = []
    const values = []
    let sortData = distanceVehicles?.sort((a, b) => a.distance - b.distance)
    sortData = sortData?.slice(0, 10)
    for (let item of sortData) {
      labels.push(item?.id)
      values.push(item?.distance)
    }
    setRadarGraphData({
      labels: labels,
      data: values
    })
  }

  const getRoutes = async (x) => {
    let route = []
    await fetch(x)
      .then(function (response) {
        return response.text();
      })
      .then(function (xml) {
        route = kml(new DOMParser().parseFromString(xml, "text/xml")).features
        route.map((item, idx) => item.segment_id = idx + 1)
        setRoute(route)
        return route
      });
  }

  const getGeoFenceData = () => {
    try {
      fetch(allsettings?.find(item => item?.name === "geo_fence")?.value)
        .then(function (response) {
          return response.text();
        })
        .then(function (xml) {
          let geo = kml(new DOMParser().parseFromString(xml, "text/xml")).features
          setGeoFence(geo)
        });

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const kml = allsettings?.find(item => item.name === "route").value
    const geo = allsettings?.find(item => item.name === "geo_fence").value
    if (kml) {
      getRoutes(kml)
      fetchVehicles(kml, transporter_id, geo)
    }
  }, [allsettings])

  useEffect(() => {
    calculateCounters()
  }, [filters.route, gatepasses])

  useEffect(() => {
    fetchGatePasses()
    return () => {
      subscription.unsubscribe()
      subscription2.unsubscribe()
    }
  }, [])


  useEffect(() => {
    if (Object.values(vehicles).length > 0) {
      segmentCalculation()
      liveTrafficCalculation()
      ETACalculation()
      // countVehiclesInGeofences(geoFence, Object.values(vehicles))
    }
  }, [vehicles])


  useEffect(() => {
    getGeoFenceData()
  }, [allsettings])

  useEffect(() => {
    const geo = allsettings?.find(item => item.name === "geo_fence").value
    getVehicleInGeofence(geo)
  }, [filters?.route, allsettings])

  if (!roles?.includes("TRAFFIC")) {
    return <NoAccess />
  }


  return (
    <Spinner show={spinner}>
      <div className='container-fluid '>
        <div className="row">
          <Spinner show={segmentSpinner}>
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className='w-25 mt-2'>
                {/* <Select placeholder={'Route'} theme={selectColor} options={routeOptions} onChange={(e)=>{
               setFilters((prev)=>({...prev,route:e}))
              }}/> */}
                <AsyncSelect isClearable theme={selectColor} required placeholder="Select Route" cacheOptions loadOptions={handleSearchRoute} defaultOptions onChange={(e) => setFilters((prev) => ({ ...prev, route: e }))} />
              </div>

            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div className="col-lg-6 card p-2 mb-3">
                  <div className="d-flex p-2">
                    <div>
                      <img src="/img/icons/tipper.png" alt="tipper" className='dashboard-icons' />
                    </div>
                    <div className='d-flex  flex-column ps-3 dashboard-counter'>
                      <span className='title'>Total Tippers</span>
                      <span className='counter'>{counter?.tippers}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 card p-2 mb-3">
                  <div className="d-flex p-2">
                    <div >
                      <img src="/img/icons/trips.png" alt="trips" className='dashboard-icons' />
                    </div>
                    <div className='d-flex  flex-column ps-3 dashboard-counter'>
                      <span className='title'>Total Trips</span>
                      <span className='counter'>{counter?.trips}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Segment wise data loaded */}
            <div className="col-lg-4">
              <div>
                <div className="card overflow-auto dashboard-card mb-3">
                  <h4 className='m-0 p-2'>Section Wise Traffic towards siding (Loaded
                    -  {filters?.route ? Object.values(vehicles).filter(item => item.loaded === true && item?.route_id === filters?.route?.value).length : Object.values(vehicles).filter(item => item.loaded === true).length})
                  </h4>
                  <table className="table table-sm table-nowrap card-table" >
                    <thead>
                      <tr className='segment-row'>
                        <th className="text-muted" >Section Name</th>
                        <th className="text-muted" >Vehicles</th>
                        <th className="text-muted" >Moving</th>
                        <th className="text-muted" >Idle</th>
                        <th className="text-muted" >Halted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(segmentsData?.loaded)?.map((item, idx) => (
                        <tr className='segment-row' key={idx}>
                          <td><Link to={`/tracker?segment=${item?.segName}`}> {item?.segName}</Link></td>
                          <td className='text-center'>{item?.vehicles}</td>
                          <td className='text-center'><div className='bg-green-100'>{item?.moving}</div></td>
                          <td className='text-center'><div className='bg-purple-100'>{item?.idle}</div></td>
                          <td className='text-center'><div className='bg-yellow-100'>{item?.halted}</div></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='card mb-3'>
                <h4 className=' m-0 p-2'>Section Wise Live Traffic</h4>
                <div className=" overflow-auto p-3" style={{ height: "310px " }}>
                  <Bar data={livetrackingData} options={option} />
                </div>
              </div>
            </div>

            {/* Segment wise data unloaded */}
            <div className="col-lg-4">
              <div>
                <div className="card dashboard-card overflow-auto mb-3">
                  <h4 className='m-0 p-2'>Section Wise Traffic towards mines (Empty
                    - {filters?.route ? Object.values(vehicles).filter(item => item.loaded !== true && item?.route_id === filters?.route?.value && item?.type_id === 10003).length : Object.values(vehicles).filter(item => item.loaded !== true && item?.type_id === 10003).length}
                    )</h4>
                  <table className="table table-sm table-nowrap card-table" >
                    <thead>
                      <tr className='segment-row '>
                        <th className="text-muted" >Section Name</th>
                        <th className="text-muted" >Vehicles</th>
                        <th className="text-muted" >Moving</th>
                        <th className="text-muted" >Idle</th>
                        <th className="text-muted" >Halted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(segmentsData?.unloaded)?.map((item, idx) => (
                        <tr className='segment-row ' key={idx}>
                          <td><Link to={`/tracker?segment=${item?.segName}`}>{item?.segName}</Link></td>
                          <td className='text-center'>{item?.vehicles}</td>
                          <td className='text-center'><div className='bg-green-100'>{item?.moving}</div></td>
                          <td className='text-center'><div className='bg-purple-100'>{item?.idle}</div></td>
                          <td className='text-center'><div className='bg-yellow-100'>{item?.halted}</div></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Unloding point railway siding Pakur */}
            <div className="col-lg-4">
              <div className="card dashboard-card ">
                <h4 className='m-0 p-2'>Unloading Point: Railway Siding</h4>
                <table className="table table-sm table-nowrap card-table" >
                  <thead>
                    <tr className='segment-row'>
                      <th className="text-muted" >Sl.No</th>
                      <th className="text-muted" >Vehicle No</th>
                      <th className="text-muted" >ETA</th>
                      <th className="text-muted" >Route</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eta[filters?.route?.label ? filters?.route?.label.toLowerCase() : 'allRoute']?.length > 0 && eta[filters?.route?.label ? filters?.route?.label.toLowerCase() : 'allRoute']?.map((item, index) => (
                      <tr key={index} className='segment-row'>
                        <td>{index + 1}</td>
                        <td>{item?.id}</td>
                        <td className='text-center'><div className='bg-green-200 '>{item?.eta}</div></td>
                        <td>{item?.route}</td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>

              </div>
            </div>

            {/* Patrol vehicle */}
            {/* <div className="col-lg-4">
              <div className="card">
                <h4 className='m-0 p-2'>Patrol Vehicles</h4>
                <div className="dashboard-card p-3">
                  <AsyncSelect isClearable theme={selectColor} required placeholder="Select Vehicle" cacheOptions loadOptions={handleSearchVehicles} defaultOptions onChange={(e) => e?.value &&  filterPatrol(e?.value)} />
                  {radarGraphData?.data?.length > 0 && <Radar data={patrolingGraph} options={config} />}
                </div>
              </div>
            </div> */}
            <div className="col-lg-4">
              <div className="card dashboard-card">
                <h4 className='m-0 p-2'>Trucks/Loaders Count</h4>
                <table className="table table-sm table-nowrap card-table" >
                  <thead>
                    <tr className='segment-row'>
                      <th >Geofence</th>
                      <th className='text-center'>Trucks</th>
                      <th className='text-center'>Loaders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(geoFenceCount).map(item => (
                      <tr key={item} className='segment-row'>
                        <td >{item}</td>
                        <td align='center'>{geoFenceCount[item].trucks}</td>
                        <td align='center'>{geoFenceCount[item].loaders}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
            {/* Loading point */}
            <div className="col-lg-4">
              <div className="card dashboard-card">
                <h4 className='m-0 p-2'>Loading Point: Pacchwara Mine</h4>
                <table className="table table-sm table-nowrap card-table" >
                  <thead>
                    <tr className='segment-row'>
                      <th className="text-muted" >Sl.No</th>
                      <th className="text-muted" >Vehicle No</th>
                      <th className="text-muted" >ETA</th>
                      <th className="text-muted" >Route</th>
                    </tr>
                  </thead>
                  <tbody>
                    {eta?.mine?.length > 0 && eta?.mine?.map((item, index) => (
                      <tr key={index} className='segment-row'>
                        <td>{index + 1}</td>
                        <td>{item?.id}</td>
                        <td className='text-center'><div className='bg-green-200 '>{item?.eta}</div></td>
                        <td>{item?.route}</td>
                      </tr>
                    ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </Spinner>
        </div>
      </div>
    </Spinner>
  )
}

export default TrafficDashboard


